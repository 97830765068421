/* istanbul ignore file */
import { allMappedCountries, isRequiredToActivate, RecipientType, TicketReasonList } from "@trolley/common-frontend";
import { useEffect, useState } from "react";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { useTickets } from "store/hooks/tickets";
import { useProducts } from "utils/context";
import { showBrn, showGovernmentId, showPhone } from "utils/helpers/products";

export default function useProfileComplete() {
  const recipient = useRecipient();
  const merchant = useMerchant();
  const { data: tickets } = useTickets();
  const config = useIframeConfig();
  const { queryProducts, entitledQueriedProducts } = useProducts();
  const [isProfileComplete, setIsProfileComplete] = useState(false);

  useEffect(() => {
    const checkProfile = () => {
      if (!merchant || !recipient) return false;
      if (!recipient.type || (recipient.type !== RecipientType.INDIVIDUAL && recipient.type !== RecipientType.BUSINESS))
        return false;
      if (!recipient.email) return false;

      if (recipient.type === RecipientType.BUSINESS) {
        if (!recipient.name) return false;
        if (!recipient.brn && showBrn({ merchant, queryProducts, entitledQueriedProducts, type: recipient.type }))
          return false;
      }

      if (recipient.type === RecipientType.INDIVIDUAL) {
        if (!recipient.firstName) return false;
        if (!recipient.lastName) return false;

        // DOB
        const requiresDOB = () =>
          !!recipient?.inactiveReasons?.dob ||
          isRequiredToActivate("dob", recipient.address.country, recipient.type) ||
          tickets.records.some((t) => t.reasons[TicketReasonList.MISSING_DOB as TicketReasonList]);
        if (!recipient.dob && requiresDOB()) return false;
      }

      if (!recipient.address.country) return false;
      if (!recipient.address.street1) return false;
      if (!recipient.address.region && !!allMappedCountries[recipient.address.country]?.regions.length) return false;
      if (
        !recipient.address.postalCode &&
        (isRequiredToActivate("postalCode", recipient.address.country) || !!recipient.inactiveReasons?.postalCode)
      )
        return false;

      if (
        !recipient.address.phone &&
        showPhone({
          merchant,
          recipient,
          country: recipient.address.country,
          queryProducts,
          entitledQueriedProducts,
        })
      )
        return false;
      if (
        !recipient.governmentId &&
        showGovernmentId({
          country: recipient.address.country,
          type: recipient.type as RecipientType,
          recipient,
          tickets: tickets.records,
          queryProducts,
          entitledQueriedProducts,
        })
      )
        return false;

      return true;
    };

    setIsProfileComplete(() => checkProfile());
  }, [merchant, recipient, tickets, config, queryProducts, entitledQueriedProducts]);

  return isProfileComplete;
}
