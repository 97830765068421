import { FormInstance } from "antd";
import { Container, Divider, Form2, GreyBox, Icon, Typography } from "components";
import React from "react";
import { WizardPageProps } from ".";
import { useIntl } from "utils/context";
import { CountryCode } from "@trolley/common-frontend";

interface IFormData {
  businessCountry: string;
  businessNumber: string;
}

export default function EnterABN({ taxProfileWizard, onContinue }: WizardPageProps) {
  const { formatMessage } = useIntl();
  const initialValues: IFormData = {
    businessCountry: CountryCode.AU,
    businessNumber: taxProfileWizard.businessNumber ?? "",
  };

  function onSubmit({ businessNumber }: IFormData) {
    onContinue({
      businessCountry: CountryCode.AU,
      businessNumber,
    });
  }

  return (
    <Form2 initialValues={initialValues} validateTrigger="onChange" requiredMark={false} onFinish={onSubmit}>
      <Container padding="none">
        <fieldset>
          <legend>
            <Typography.Title>{formatMessage({ id: "containers.taxProfile.wizard.anABN" })}</Typography.Title>
          </legend>
          <Divider transparent size="large" />
          <GreyBox border={false}>
            <Typography.Paragraph style={{ marginBottom: 0 }}>
              {formatMessage({ id: "containers.taxProfile.wizard.abnHelp" })}
            </Typography.Paragraph>
          </GreyBox>
          <Divider transparent size="large" />
          <Form2.Item
            name="businessNumber"
            label={formatMessage({ id: "containers.taxProfile.wizard.abn" })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.taxProfile.wizard.abnInvalid.enterABN" }),
              },
              {
                pattern: /^[0-9]{11}$/,
                message: formatMessage({ id: "containers.taxProfile.wizard.abnInvalid.invalidFormat" }),
              },
            ]}
          >
            <Form2.Input
              type="text"
              placeholder={formatMessage({ id: "containers.taxProfile.wizard.businessNumber" })}
              maxLength={20}
            />
          </Form2.Item>
        </fieldset>
        <Divider transparent size="large" />
        <Form2.Item dependencies={["businessCountry", "businessNumber"]}>
          {(form: FormInstance<IFormData>) => {
            const businessCountry = form.getFieldValue("businessCountry");
            const businessNumber = form.getFieldValue("businessNumber");

            return (
              <Form2.SubmitButton
                disabled={!businessCountry || !businessNumber}
                text={formatMessage({ id: "common.continue" })}
                suffix={<Icon type="arrow-right" />}
              />
            );
          }}
        </Form2.Item>
      </Container>
    </Form2>
  );
}
