import { Layout } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import { Button, Container, Icon, Text } from "components";
import { LanguageSelector } from "features";
import AccountSummary from "pages/AccountSummary";
import { KybUpload } from "pages/Trust";
import { useNewStyle } from "pages/App";
import Info from "pages/Info";
import Info2 from "pages/Info2";
import PageNotFound from "pages/PageNotFound";
import Payments from "pages/Payments";
import PayoutComplete from "pages/PayoutComplete";
import PayoutMethod from "pages/PayoutMethod";
import PayoutMethod2 from "pages/PayoutMethod2";
import PortalLogoutButton from "pages/Portal//PortalLogoutButton";
import PortalLogin from "pages/Portal/PortalLogin";
import PortalSidebar from "pages/Portal/PortalSidebar";
import TaxComplete from "pages/TaxComplete";
import TaxFormDelivery from "pages/TaxFormDelivery";
import TaxFormEdit from "pages/TaxSection/TaxFormEdit";
import UploadTaxForm from "pages/TaxSection/UploadTaxForm";
import TaxSection from "pages/TaxSection/index";
import TimeOut from "pages/TimeOut";
import OnboardingShowRoom from "pages/Onboarding/ShowRoom";
import OnBoardingTaxProfile from "pages/Onboarding/TaxProfile";
import OnboardingTheme from "pages/Onboarding/OnboardingTheme";
import { datadog } from "services/thirdParty";
import { SessionMode } from "store/actions/auth";
import { useAuth } from "store/hooks/auth";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import css, { ThemeProps, classnames, createUseStyle, useTheme } from "style/classname";
import {
  ClientContext,
  PRODUCT_MODULES,
  StepperProvider,
  useClientIntegration,
  useIntl,
  useProducts,
} from "utils/context";
import { emitEvent, getContrastText, showProduct, WidgetEvent } from "utils/helpers";
import { useWindowSize } from "utils/hooks";
import AppHeader from "./AppHeader";
import { PATHS } from "./routes";
import RestrictedRoute from "./RestrictedRoute";

export default function MainContainer() {
  const client = useClientIntegration();
  const body = useRef<null | HTMLElement>(null);
  const theme = useTheme();
  const merchant = useMerchant();
  const iframeConfig = useIframeConfig();
  const { auth, isLoggedIn } = useAuth();
  const recipient = useRecipient();
  const newLayout = useNewStyle();
  const [showNotif, setShowNotif] = useState(false);
  const history = useHistory<{ profileVisited?: boolean; modularWidgetPath?: string }>();
  const styledContainer = useContainerStyle({ client });
  const styledNewContent = useNewContainerStyle({ client });
  const styledPortalLogout = useStyledPortalLogout();
  const { isMobile } = useWindowSize();
  const { formatMessage } = useIntl();
  const { queryProducts, entitledQueriedProducts } = useProducts();
  const showTaxProduct = showProduct({ product: PRODUCT_MODULES.TAX, queryProducts, entitledQueriedProducts });
  const showPayProduct = showProduct({ product: PRODUCT_MODULES.PAY, queryProducts, entitledQueriedProducts });
  const showTrustProduct = showProduct({ product: PRODUCT_MODULES.TRUST, queryProducts, entitledQueriedProducts });

  useEffect(() => {
    if (recipient?.id) {
      datadog.start(recipient);
    } else {
      datadog.end();
    }
  }, [recipient?.id]);

  useEffect(() => {
    if (client === "portal") {
      if (auth.state === SessionMode.ENDED || auth.state === SessionMode.EXPIRED) {
        setShowNotif(true);
      }
    }
  }, [auth.state]);

  useEffect(() => {
    if (!body.current) {
      const root = document.getElementById("root");
      if (root) {
        body.current = root;
      }
    }
    if (body.current) {
      body.current.className = globalTheme(theme);
    }
  }, [theme]);

  useEffect(() => {
    emitEvent({
      event: WidgetEvent.DOCUMENT_LOADED,
    });
  }, []);

  const mainContent = (
    <Switch>
      <Route
        path={PATHS.WIZARD}
        render={() => (
          <OnboardingTheme>
            <Switch>
              <Route exact path={PATHS.WIZARD_SHOWROOM} component={OnboardingShowRoom} />
              {merchant?.features?.euTax && merchant?.euTax?.enabled && (
                <RestrictedRoute
                  isEntitled={showTaxProduct}
                  path={[PATHS.WIZARD_TAXPROFILE_NEW, `${PATHS.WIZARD_TAXPROFILE}/:taxProfileId`]}
                  component={OnBoardingTaxProfile}
                />
              )}
              <Route component={PageNotFound} />
            </Switch>
          </OnboardingTheme>
        )}
      />
      <Route
        render={() => (
          <>
            {iframeConfig.faq && iframeConfig.faqLink && (
              <Text align="center" padded>
                <Button
                  type="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    new RegExp("^(?:[a-z]+:)?//", "i").test(iframeConfig.faqLink)
                      ? iframeConfig.faqLink
                      : `//${iframeConfig.faqLink}`
                  }
                >
                  {formatMessage({
                    id: "containers.accountSummary.learnMoreFAQ",
                  })}
                </Button>
              </Text>
            )}
            <Switch>
              <Route exact path={PATHS.HOME} component={AccountSummary} />
              <RestrictedRoute isEntitled={showTrustProduct} path={PATHS.KYB_UPLOAD} exact component={KybUpload} />
              <RestrictedRoute isEntitled={showPayProduct} path={PATHS.PAYMENTS} component={Payments} />
              <Route
                path={PATHS.US_TAX}
                render={() => {
                  if (merchant?.features?.tax && merchant?.tax?.enabled && iframeConfig?.usTax) {
                    return (
                      <Switch>
                        <RestrictedRoute
                          isEntitled={showTaxProduct}
                          path={PATHS.US_TAX_COMPLETE}
                          component={TaxComplete}
                        />
                        {iframeConfig?.taxFormUpload && (
                          <RestrictedRoute
                            isEntitled={showTaxProduct}
                            path={`${PATHS.US_TAX_UPLOAD}/:taxId(TX-\\w+)?`}
                            component={UploadTaxForm}
                          />
                        )}
                        <RestrictedRoute
                          isEntitled={showTaxProduct}
                          path={PATHS.US_TAX_DELIVERY}
                          render={() => (
                            <TaxFormDelivery
                              onComplete={() => {
                                history.push(PATHS.US_TAX_COMPLETE);
                              }}
                              onExit={() => {
                                history.push(PATHS.HOME);
                              }}
                            />
                          )}
                        />
                        <RestrictedRoute isEntitled={showTaxProduct} path={PATHS.US_TAX} exact component={TaxSection} />
                        <RestrictedRoute
                          isEntitled={showTaxProduct}
                          path={[PATHS.US_TAX_NEW, `${PATHS.US_TAX}/:taxId`]}
                          component={TaxFormEdit}
                        />
                        <Route component={PageNotFound} />
                      </Switch>
                    );
                  }

                  return <PageNotFound />;
                }}
              />
              <RestrictedRoute isEntitled={showPayProduct} path={PATHS.PAYOUT_COMPLETE} component={PayoutComplete} />
              <Route path={PATHS.INFO} component={newLayout ? Info2 : Info} />
              <RestrictedRoute
                isEntitled={showPayProduct}
                path={`${PATHS.PAYOUT}/:accountId(A-\\w+)?`}
                component={newLayout ? PayoutMethod2 : PayoutMethod}
              />
              <Route component={PageNotFound} />
            </Switch>
          </>
        )}
      />
    </Switch>
  );

  if (client === "portal") {
    if (!merchant) {
      return null;
    }

    return newLayout ? (
      <StepperProvider>
        <AppHeader />
        <Layout.Content className={styledNewContent}>
          {showNotif && auth.state && [SessionMode.ENDED, SessionMode.EXPIRED].includes(auth.state) && (
            <Text size="large" align="center" role="alert" padded>
              {auth.state === SessionMode.ENDED && (
                <>
                  <Icon color="success" type="check-circle" left />
                  {formatMessage({
                    id: "containers.mainContainer.successfulLogout",
                  })}
                </>
              )}
              {auth.state === SessionMode.EXPIRED && (
                <>
                  <Icon color="warning" type="stopwatch" left />
                  {formatMessage({ id: "containers.mainContainer.sessionExpired" })}
                </>
              )}
              <Button
                type="text"
                size="middle"
                icon="times"
                aria-label={formatMessage({ id: "common.close" })}
                onClick={() => {
                  setShowNotif(false);
                }}
              />
            </Text>
          )}
          {isLoggedIn ? mainContent : <PortalLogin />}
        </Layout.Content>
      </StepperProvider>
    ) : (
      <PortalSidebar>
        {!isMobile && <PortalLogoutButton className={styledPortalLogout} />}
        <Layout.Content
          className={classnames(styledContainer, {
            logout: !isLoggedIn,
          })}
        >
          {showNotif && auth.state && [SessionMode.ENDED, SessionMode.EXPIRED].includes(auth.state) && (
            <Text size="large" align="center" role="alert" padded>
              {auth.state === SessionMode.ENDED && (
                <>
                  <Icon color="success" type="check-circle" left />
                  {formatMessage({
                    id: "containers.mainContainer.successfulLogout",
                  })}
                </>
              )}
              {auth.state === SessionMode.EXPIRED && (
                <>
                  <Icon color="warning" type="stopwatch" left />
                  {formatMessage({ id: "containers.mainContainer.sessionExpired" })}
                </>
              )}
              <Button
                type="text"
                size="middle"
                icon="times"
                aria-label={formatMessage({ id: "common.close" })}
                onClick={() => {
                  setShowNotif(false);
                }}
              />
            </Text>
          )}
          {isLoggedIn ? mainContent : <PortalLogin />}
        </Layout.Content>
      </PortalSidebar>
    );
  }

  if (auth.state === SessionMode.EXPIRED) {
    return <TimeOut />;
  }

  return (
    <Layout.Content className={newLayout ? styledNewContent : styledContainer}>
      {iframeConfig.showLanguage && (
        <Container padding="small" style={{ textAlign: "right" }}>
          <LanguageSelector />
        </Container>
      )}
      {isLoggedIn && <StepperProvider>{mainContent}</StepperProvider>}
    </Layout.Content>
  );
}

const useNewContainerStyle = createUseStyle<{ client: ClientContext }>(({ theme, client }) =>
  css`
    ${client === "portal" &&
    `
      max-width: ${theme.screenMD}px;
      width: 100%;
      margin: 0 auto;
      min-height: calc(100vh - ${theme.Layout?.headerHeight || 64}px);
     `}
  `(),
);

const useContainerStyle = createUseStyle<{ client: ClientContext }>(({ theme, client }) =>
  css`
    &.${theme.prefixCls}-layout-content {
      flex: 0 0 auto;
    }

    ${client === "portal" &&
    `
      max-width: ${theme.screenMD}px;
      width: 100%;
      margin: 0 auto;
      padding: 16px 0;
      min-height: calc(100vh - 140px);

      &.logout {
        animation: ease-in;
        position: relative;
        margin-bottom: 0px;
        padding: 0px 16px;
      }

      ${theme.screenUp("md")} {
        background-color: #fff;
        border-radius: ${theme.borderRadiusLG}px;
        border: none;
        box-shadow: 0 4px 20px rgba(100, 100, 100, 0.2);
        padding: 0;
        &.logout {
          margin-bottom: 30px;
          padding: 24px 16px;
        }
      }
    `}
  `(),
);

export const globalTheme = css<ThemeProps["theme"]>`
  &#root {
    font-size: ${(theme) => theme.fontSize}px;
    color: ${(theme) => theme.colorText};
    background-color: ${(theme) => theme.colorBgBase};
  }
`;

export const useStyledPortalLogout = createUseStyle(({ theme }) =>
  css`
    &.${theme.prefixCls}-btn {
      min-width: auto;
      border: none !important;
      transition: all 0.2s ease;
      position: absolute;
      padding-left: 16px;
      padding-right: 16px;
      top: 8px;
      right: 8px;
      ${theme.screenDown("md")} {
        top: 0px;
        right: 0px;
        // this is because the logout button will be above the sidebar header
        color: ${getContrastText(theme.colorBrand)} !important;
      }
    }
  `(),
);
