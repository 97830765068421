/* istanbul ignore file */
import * as types from "@trolley/common-frontend";
import { RecursivePartial, PhoneVerificationChannels, PhoneVerifStatusResponse } from "@trolley/common-frontend";
import * as request from "services/request";
import store from "store";
import * as ACTIONS from "store/actions";
import { OpCode, standardDispatch } from "store/dispatcher";
import { PRODUCT_MODULES } from "utils/context";
import { emitEvent, WidgetEvent } from "utils/helpers";

export interface Recipient extends types.Recipient {}
export interface RecipientAccount extends types.RecipientAccount {}
export interface BaseRecipientAccountUpdate {
  primary?: boolean;
  type: types.PayoutMethodType;
}

export interface BankTransferAccountUpdate extends BaseRecipientAccountUpdate {
  currency: types.CurrencyCode;
  country: types.CountryCode;
  iban?: string;
  accountNum?: string;
  accountHolderName?: string;
  swiftBic?: string;
  branchId?: string;
  bankId?: string;
  bankName?: string;
  bankAddress?: string;
  bankCity?: string;
  bankCodeMappingId?: number;
  bankRegionCode?: string;
}

export interface CheckAccountUpdate extends BaseRecipientAccountUpdate {
  mailing: {
    name: string;
    street1: string;
    street2: string;
    city: string;
    region: string;
    postal: string;
    country: string;
  };
}

export interface InteracAccountUpdate extends BaseRecipientAccountUpdate {
  currency: types.CurrencyCode.CAD;
  country: types.CountryCode.CA | null;
  emailAddress: string;
}

export interface PayPalAccountUpdate extends BaseRecipientAccountUpdate {
  // currency: types.CurrencyCode.USD;
  emailAddress: string;
}

export interface BitcoinAccountUpdate extends BaseRecipientAccountUpdate {
  wallet: string;
}

export interface VenmoAccountUpdate extends BaseRecipientAccountUpdate {
  currency: types.CurrencyCode.USD;
  country: types.CountryCode | null;
  phoneNumber: string;
}

interface PayoutMethod extends types.PayoutMethod.PayoutMethodPublic {}
type PaypalSettings = types.PayoutMethod.PaypalPublicSettings;
type VenmoSettings = types.PayoutMethod.VenmoPublicSettings;

export function isPaypalSettings(
  integration: PayoutMethod["integration"] | undefined,
  settings: PayoutMethod["settings"],
): settings is PaypalSettings {
  return integration === "paypal";
}

export function isVenmoSettings(
  integration: PayoutMethod["integration"] | undefined,
  settings: PayoutMethod["settings"],
): settings is VenmoSettings {
  return integration === "venmo";
}

export function loadRecipient(recipientId: string) {
  request
    .GET<types.RecipientResponse>(`/v1/recipients/${recipientId}`)
    .then(({ body }) => {
      standardDispatch(OpCode.DATA, "recipient", {
        data: body.recipient,
      });
    })
    .catch((errors) => {
      store.dispatch({
        type: ACTIONS.ERROR_AUTH,
        data: errors,
      });
    });
}

export type RecipientUpdate = RecursivePartial<
  Pick<
    Recipient,
    | "address"
    | "email"
    | "firstName"
    | "lastName"
    | "taxDeliveryType"
    | "governmentId"
    | "dob"
    | "occupation"
    | "citizenships"
    | "language"
    | "birthplace"
    | "legalName"
    | "governmentIds"
  >
>;
export async function updateRecipient(recipientId: string, data: RecipientUpdate): Promise<Recipient> {
  try {
    const res = await request.PATCH<types.RecipientResponse>(`/v1/recipients/${recipientId}`, { query: data });
    standardDispatch(OpCode.DATA, "recipient", {
      data: res.body.recipient,
    });

    emitEvent({
      event: WidgetEvent.MODULE_SUCCESSFUL,
      module: ["profile"],
    });

    return res.body.recipient;
  } catch (error) {
    emitEvent({
      event: WidgetEvent.MODULE_FAILED,
      module: ["profile"],
    });

    throw error;
  }
}

export async function addPayoutMethod(recipientId: string, data: any) {
  try {
    const {
      body: { account },
    } = await request.POST<types.RecipientAccountResponse>(`/v1/recipients/${recipientId}/accounts`, { query: data });
    emitEvent({ event: WidgetEvent.PAYOUT_CREATED, account: { id: account.id } });
    emitEvent({
      event: WidgetEvent.MODULE_SUCCESSFUL,
      module: [PRODUCT_MODULES.PAY],
    });

    loadRecipient(recipientId);
  } catch (err) {
    emitEvent({
      event: WidgetEvent.MODULE_FAILED,
      module: [PRODUCT_MODULES.PAY],
    });
    throw err;
  }
}

export async function updatePayoutMethod(recipientId: string, accountId: string, data: any) {
  const {
    body: { account },
  } = await request.PATCH<types.RecipientAccountResponse>(`/v1/recipients/${recipientId}/accounts/${accountId}`, {
    query: data,
  });
  if (accountId !== account.id) {
    emitEvent({
      event: WidgetEvent.PAYOUT_DELETED,
      account: { id: accountId },
    });
    emitEvent({
      event: WidgetEvent.PAYOUT_CREATED,
      account: { id: account.id },
    });
  }
  loadRecipient(recipientId);
}

export async function deletePayoutMethod(recipientId: string, accountId: string) {
  await request.DELETE(`/v1/recipients/${recipientId}/accounts/${accountId}`);
  emitEvent({ event: WidgetEvent.PAYOUT_DELETED, account: { id: accountId } });
  loadRecipient(recipientId);
}

export async function loadPhoneVerification({
  phone,
  channel = PhoneVerificationChannels.SMS,
  code,
  phoneExtension,
}: {
  phone: string;
  channel?: PhoneVerificationChannels;
  code?: string;
  phoneExtension?: string;
}) {
  try {
    const res = await request.POST<PhoneVerifStatusResponse>("/v1/recipients/phone/verify", {
      query: { phone, channel, code, phoneExtension },
    });
    standardDispatch(OpCode.UPDATE, "recipient", { update: { isPhoneVerified: res.body.isPhoneVerified } });

    return res.body.isPhoneVerified;
  } catch (errors) {
    standardDispatch(OpCode.UPDATE, "recipient", { update: { isPhoneVerified: false } });
    throw errors;
  }
}
