import {
  CountryCode,
  Recipient,
  RecipientType,
  Ticket,
  TicketReasonList,
  WidgetMerchant,
  isRequiredToActivate,
} from "@trolley/common-frontend";
import { PRODUCT_MODULES } from "utils/context";

export function showProduct({
  product,
  queryProducts,
  entitledQueriedProducts,
}: {
  product: PRODUCT_MODULES;
  queryProducts: PRODUCT_MODULES[];
  entitledQueriedProducts: PRODUCT_MODULES[];
}) {
  if (queryProducts.length === 0) {
    return true;
  }

  return entitledQueriedProducts.includes(product);
}

export function showGovernmentId(input: {
  country?: CountryCode;
  type: RecipientType;
  recipient: Recipient;
  tickets: Ticket[];
  queryProducts: PRODUCT_MODULES[];
  entitledQueriedProducts: PRODUCT_MODULES[];
}): boolean {
  const { country, type, recipient, tickets, queryProducts, entitledQueriedProducts } = input;

  return !!(
    recipient?.governmentIds.length ||
    tickets.some(
      (t) => TicketReasonList.CHECK_PASSPORT_NUMBER in t.reasons || TicketReasonList.CHECK_ID_NUMBER in t.reasons,
    ) ||
    (showProduct({ product: PRODUCT_MODULES.PAY, queryProducts, entitledQueriedProducts }) &&
      (isRequiredToActivate("govId", country, type) || recipient.inactiveReasons?.governmentId))
  );
}

export function showPhone(input: {
  merchant: WidgetMerchant | null;
  country?: CountryCode;
  recipient: Recipient;
  queryProducts: PRODUCT_MODULES[];
  entitledQueriedProducts: PRODUCT_MODULES[];
}): boolean {
  const { merchant, country, recipient, queryProducts, entitledQueriedProducts } = input;

  const phoneActivated = !!merchant?.features?.phoneVerification && merchant?.trust?.requirePhoneVerification;

  return !!(
    (phoneActivated && showProduct({ product: PRODUCT_MODULES.TRUST, queryProducts, entitledQueriedProducts })) ||
    (showProduct({ product: PRODUCT_MODULES.PAY, queryProducts, entitledQueriedProducts }) &&
      (isRequiredToActivate("phone", country) || recipient.inactiveReasons?.phone))
  );
}

export function showBrn(input: {
  merchant: WidgetMerchant | null;
  queryProducts: PRODUCT_MODULES[];
  entitledQueriedProducts: PRODUCT_MODULES[];
  type: RecipientType;
}): boolean {
  const { merchant, queryProducts, entitledQueriedProducts, type } = input;
  const dsaActivated = !!merchant?.features?.dsa && merchant?.trust?.requireDSA;

  return !!(
    type === RecipientType.BUSINESS &&
    dsaActivated &&
    showProduct({ product: PRODUCT_MODULES.TRUST, queryProducts, entitledQueriedProducts })
  );
}
