import React, { useEffect, useState } from "react";
import { useIframeConfig } from "store/hooks/config";
import { useVerifications } from "store/hooks/idVerification";
import { useRecipient } from "store/hooks/recipient";
import FirstVerification from "./FirstVerification";
import VerificationHistoryList from "./VerificationHistoryList";
import { BaseStatus } from "store/reducers/standardReducer";
import { Loader } from "components";

export default function VerificationHistory() {
  const recipient = useRecipient();
  const config = useIframeConfig();
  const { data: verifications, status: verificationsStatus } = useVerifications(
    config.showVerificationHistory ? recipient?.id ?? "" : "",
  );
  const [hasHistory, setHasHistory] = useState(false);

  useEffect(() => {
    if (
      verificationsStatus === BaseStatus.LOADED &&
      verifications &&
      (verifications.individual.length > 0 || verifications.business.length > 0)
    ) {
      setHasHistory(true);
    }
  }, [verifications, verificationsStatus]);

  if (verificationsStatus === BaseStatus.LOADING) {
    return <Loader spinning />;
  }

  if (hasHistory) {
    return <VerificationHistoryList />;
  }

  return <FirstVerification />;
}

export { default as KybUpload } from "./KybUpload";
