/* istanbul ignore file */
import * as types from "@trolley/common-frontend";
import {
  EndOfYearFormType,
  RecursivePartial,
  TaxFormType,
  TaxW8LimitationTypes,
  TaxWithholdingReasons,
  UsUploadTaxFormType,
} from "@trolley/common-frontend";
import { batch } from "react-redux";
import * as request from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Mapped } from "store/reducers/standardReducer";
import { createFormData, emitEvent, WidgetEvent } from "utils/helpers";
import { isLoaded } from "./actionUtils";
import { RcFile } from "rc-upload/lib/interface";
import { PRODUCT_MODULES } from "utils/context";

export interface TaxForm extends types.TaxForm.TaxForm {}

export interface TaxDataW9 extends types.TaxForm.W9Form {}
export interface FormW9 extends TaxForm {
  data: TaxDataW9;
  taxFormData: TaxDataW9;
}

// export interface TaxDataW8BEN extends types.TaxForm.W8BENForm {}
export interface TaxDataW8BEN extends Omit<types.TaxForm.W8BENEForm, "beneficialOwnerLimitationType"> {
  beneficialOwnerLimitationType?: TaxW8LimitationTypes | string;
}
export interface FormW8BEN extends TaxForm {
  data: TaxDataW8BEN;
  taxFormData: TaxDataW8BEN;
}

export interface TaxDataW8BENE extends TaxDataW8BEN {}
export interface FormW8BENE extends TaxForm {
  data: TaxDataW8BENE;
  taxFormData: TaxDataW8BENE;
}

export interface TaxData8233 extends types.TaxForm.F8233Form {}

export interface UsUpload<T = TaxData8233 | TaxDataW9 | TaxDataW8BEN | TaxDataW8BENE | any>
  extends Omit<types.TaxForm.UsUpload, "w9Data" | "w8Data" | "f8223"> {
  w9Data?: T extends TaxDataW9 ? TaxDataW9 : never;
  // w8Data?: T extends TaxDataW8BEN | TaxDataW8BENE ? TaxDataW8BEN | TaxDataW8BENE : never;
  f8233?: T extends TaxData8233 ? TaxData8233 : never;
}
export interface FormUsUpload<T = TaxData8233 | TaxDataW9 | TaxDataW8BEN | TaxDataW8BENE | unknown> extends TaxForm {
  data: UsUpload<T>;
}

const TAX_FORMS_LABELS: Record<TaxFormType | UsUploadTaxFormType | EndOfYearFormType, string> = {
  [TaxFormType.W9]: "W-9",
  [TaxFormType.W8BEN]: "W-8BEN",
  [TaxFormType.W8BENE]: "W-8BEN-E",
  [TaxFormType.US_UPLOAD]: "Uploaded Form",
  [TaxFormType.F8233]: "8233",
  [UsUploadTaxFormType.W8IMY]: "W-8IMY",
  [UsUploadTaxFormType.W8ECI]: "W-8ECI",
  [UsUploadTaxFormType.W8EXP]: "W-8EXP",
  [UsUploadTaxFormType.W4]: "W-4",
  [EndOfYearFormType.F1099]: "1099-MISC",
  [EndOfYearFormType.F1099_NEC]: "1099-NEC",
  [EndOfYearFormType.F1099_K]: "1099-K",
  [EndOfYearFormType.F1042]: "1042-S",
  [EndOfYearFormType.F1099_K]: "1099-K",
  [EndOfYearFormType.NONE]: "No Form",
};

export function getTaxFormLabel(
  type: TaxFormType | UsUploadTaxFormType | EndOfYearFormType | string | null | undefined,
): string {
  return (type && TAX_FORMS_LABELS[type]) || type || "";
}

const WithholdingReasonLabels: Record<TaxWithholdingReasons, string> = {
  [TaxWithholdingReasons.SUBJECT_TO_WITHHOLDING]: "Certified that you are subject to backup withholding.",
  [TaxWithholdingReasons.RENT_SUBJECT_TO_WITHHOLDING]: "Certified that you are subject to backup withholding.",
  [TaxWithholdingReasons.NOT_SUBJECT_TO_WITHHOLDING]: "Certified that you are not subject to backup withholding.",
  [TaxWithholdingReasons.NO_US_CERTIFICATION]: "Certified that you do not perform any US Activities.",
  [TaxWithholdingReasons.TREATY_TAX_RATE]:
    "This reflects the appropriate treaty withholding rate for the country of residence.",
  [TaxWithholdingReasons.NO_TAXID_PROVIDED]: "A Tax Identification Number has not been provided.",
  [TaxWithholdingReasons.NO_TAX_TREATY]: "The country of residence is not included in the list of treaty countries.",
  [TaxWithholdingReasons.UNKNOWN_FORM_UPLOAD]: "An unknown form has been uploaded.",
  [TaxWithholdingReasons.TIN_INVALID]: "Failed US TIN Matching. A new tax form is required.",
  [TaxWithholdingReasons.NO_RESIDENCE_COUNTRY]: "Your country of residence has not been provided.",
  [TaxWithholdingReasons.INCOMPLETE_FORM]: "Your tax form is incomplete.",
};

export function getWithholdingReasonLabel(reason: string | undefined) {
  return ((reason && WithholdingReasonLabels[reason]) || reason) ?? "";
}

enum FormStatusOrder {
  reviewed,
  submitted,
  expired,
  voided,
  incomplete,
}

function sortTaxForm(formA: TaxForm, formB: TaxForm) {
  // sort by status first
  if (FormStatusOrder[formA.status] < FormStatusOrder[formB.status]) {
    return -1;
  }
  if (FormStatusOrder[formA.status] > FormStatusOrder[formB.status]) {
    return 1;
  }

  // then sort by last reviewed
  const reviewedA = formA.reviewedAt ? new Date(formA.reviewedAt).getTime() : 0;
  const reviewedB = formB.reviewedAt ? new Date(formB.reviewedAt).getTime() : 0;

  if (reviewedA > reviewedB) {
    return -1;
  }
  if (reviewedA < reviewedB) {
    return 1;
  }

  // then sort by last submitted
  const dateA = formA.signedAt ? new Date(formA.signedAt).getTime() : 0;
  const dateB = formB.signedAt ? new Date(formB.signedAt).getTime() : 0;

  if (dateA > dateB) {
    return -1;
  }
  if (dateA < dateB) {
    return 1;
  }

  return 0;
}

export function loadTaxForm(taxId: string, force?: true) {
  const {
    taxForm,
    recipient: {
      entities: { data: recipient },
    },
  } = store.getState();
  const id = taxId;
  if (force || !isLoaded(taxForm.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "taxForm", { id });
    request
      .GET<types.TaxForm.Result>(`/v1/recipients/${recipient?.id}/tax/${taxId}`)
      .then(({ body: { taxForm } }) => {
        standardDispatch(OpCode.DATA, "taxForm", {
          id,
          data: taxForm,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "taxForm", { errors, id });
      });
  }
}

export function loadTaxForms(force?: true) {
  const {
    taxForms,
    recipient: {
      entities: { data: recipient },
    },
  } = store.getState();
  const id = "data";
  if (recipient?.id && (force || !isLoaded(taxForms.fetchStatus[id]))) {
    standardDispatch(OpCode.LOADING, "taxForms", { id });

    request
      .GET<types.TaxForm.ListResult>(`/v1/recipients/${recipient.id}/tax`, {
        query: { page: 1, pageSize: 100 },
      })
      .then(({ body }) => {
        batch(() => {
          standardDispatch(OpCode.DATA, "taxForms", {
            id,
            data: {
              records: body.taxForms.sort(sortTaxForm).map((tf) => tf.id),
              meta: body.meta,
            },
          });

          standardDispatch(OpCode.DATA, "taxForm", {
            bulk: body.taxForms.reduce((acc, tf) => {
              acc[tf.id] = tf;

              return acc;
            }, {} as Mapped<TaxForm>),
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "taxForms", { errors, id });
      });
  }
}

export async function asyncLoadTaxForms(force?: true) {
  const {
    taxForms,
    recipient: {
      entities: { data: recipient },
    },
  } = store.getState();
  const id = "data";
  if (force || !isLoaded(taxForms.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "taxForms", { id });

    try {
      const { body } = await request.GET<types.TaxForm.ListResult>(`/v1/recipients/${recipient?.id}/tax`, {
        query: { page: 1, pageSize: 100 },
      });

      batch(() => {
        const records = body.taxForms.sort(sortTaxForm);
        standardDispatch(OpCode.DATA, "taxForms", {
          id,
          data: {
            records: records.map((tf) => tf.id),
            meta: body.meta,
          },
        });

        standardDispatch(OpCode.DATA, "taxForm", {
          bulk: records.reduce((acc, tf) => {
            acc[tf.id] = tf;

            return acc;
          }, {} as Mapped<TaxForm>),
        });
      });
    } catch (errors) {
      standardDispatch(OpCode.ERROR, "taxForms", { errors, id });
      throw errors;
    }
  }
}

export async function copyForm(taxId: string) {
  const {
    recipient: {
      entities: { data: recipient },
    },
  } = store.getState();
  if (recipient) {
    const {
      body: { taxForm },
    } = await request.POST<types.TaxForm.Result>("/v1/tax-form/copy", {
      query: { id: taxId, recipientId: recipient.id },
    });

    return taxForm.id;
  }

  return null;
}

export async function signAndSubmitTaxForm(taxId: string, name: string) {
  const {
    recipient: {
      entities: { data: recipient },
    },
  } = store.getState();
  try {
    standardDispatch(OpCode.LOADING, "taxForm", { id: taxId });
    await request.POST<{}>(`/v1/recipients/${recipient?.id}/tax/${taxId}/sign`, { query: { signature: name } });
    emitEvent({ event: WidgetEvent.TAXFORM_SUBMITTED, taxForm: { id: taxId } });
    emitEvent({
      event: WidgetEvent.MODULE_SUCCESSFUL,
      module: [PRODUCT_MODULES.TAX],
    });
    standardDispatch(OpCode.LOADING, "taxForm", { loading: false, id: taxId });
    loadTaxForms(true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxForm", { id: taxId, errors });
    throw errors;
  }
}

interface TaxFormCreate {
  kind: TaxFormType;
  formVersion: string;
  withholdingPercentage?: string;
  data: RecursivePartial<TaxForm["data"]>;
}

export async function createTaxForm(data: TaxFormCreate) {
  const {
    recipient: {
      entities: { data: recipient },
    },
  } = store.getState();
  try {
    standardDispatch(OpCode.LOADING, "taxForm");
    const {
      body: { taxForm },
    } = await request.POST<types.TaxForm.Result>(`/v1/recipients/${recipient?.id}/tax`, { query: data });
    batch(() => {
      standardDispatch(OpCode.DATA, "taxForm", {
        id: taxForm.id,
        data: taxForm,
      });
      loadTaxForms(true);
    });

    return taxForm;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxForm", { errors });
    throw errors;
  }
}

export async function updateTaxForm(taxId: string, data: RecursivePartial<TaxForm>) {
  const {
    recipient: {
      entities: { data: recipient },
    },
  } = store.getState();
  try {
    standardDispatch(OpCode.LOADING, "taxForm", { id: taxId });
    const {
      body: { taxForm },
    } = await request.PATCH<types.TaxForm.Result>(`/v1/recipients/${recipient?.id}/tax/${taxId}`, {
      query: data,
    });

    standardDispatch(OpCode.DATA, "taxForm", {
      id: taxId,
      data: taxForm,
    });

    return taxForm;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxForm", {
      id: taxId,
      errors,
    });
    throw errors;
  }
}

export async function uploadTaxDocument(file: RcFile) {
  const {
    recipient: {
      entities: { data: recipient },
    },
  } = store.getState();
  try {
    standardDispatch(OpCode.LOADING, "taxForm");
    const formData = createFormData([file]);
    const {
      body: { taxForm },
    } = await request.POST<types.TaxForm.Result>(`/v1/recipients/${recipient?.id}/tax/upload`, {
      isUpload: true,
      body: formData,
    });
    batch(() => {
      standardDispatch(OpCode.DATA, "taxForm", { id: taxForm.id });
      loadTaxForms(true);
    });

    return taxForm;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxForm", { errors });
    throw errors;
  }
}
