import {
  allMappedCountries,
  CountryCode,
  getPostalCodeProps,
  IncompleteReason,
  IncompleteReasonType,
  isRequiredToActivate,
  PayoutMethodType,
  Recipient,
  RecipientType,
  TicketReasonList,
} from "@trolley/common-frontend";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";

import { BackButton, Button, Container, Footer, Form2 as Form, Grid, Loader, TitleBar, Status } from "components";
import { countryPhoneValidator } from "components/Form/InputPhone";
import { isLegalAgeDateValidator } from "components/Form2/DatePicker";
import { govermentIdsValidator } from "components/Form2/GovernmentIds";
import { isCountryCode } from "components/Form2/SelectCountry";
import { isRegionInCountryValidator } from "components/Form2/SelectRegion";
import common from "locale/en/common.json";
import info from "locale/en/containers/info.json";
import { PATHS } from "pages/App/routes";
import { RecipientUpdate, updateRecipient } from "store/actions/recipient";
import { loadRecipientConfig } from "store/actions/recipientConfig";
import { IFrameConfig, useIframeConfig } from "store/hooks/config";
import { useUrlParams } from "store/hooks/params";
import { useRecipient } from "store/hooks/recipient";
import { useTickets } from "store/hooks/tickets";
import { useTheme } from "style/classname";
import { ActionType, IntlMessageKeys, PRODUCT_MODULES, useIntl, useProducts, useStepperEffect } from "utils/context";
import { emitEvent, handleFormErrors, omitMaskedValues, WidgetEvent } from "utils/helpers";
import { useWindowSize } from "utils/hooks";
import InactiveReasons from "./AccountSummary/InactiveReasons";
import { useMerchant } from "store/hooks/merchant";
import PhoneVerificationPopup from "./PhoneVerificationPopup";
import { showBrn, showGovernmentId, showPhone, showProduct } from "utils/helpers/products";

type FormFields = {
  type: string;
  email: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  governmentIds?: {
    country: CountryCode | null;
    type: string | null;
    value?: string | null;
  }[];
  brn?: string;
  dob?: string;
  address: {
    phone?: string;
    street1?: string;
    street2?: string;
    city?: string;
    region?: string;
    postalCode?: string;
    country?: CountryCode;
  };
};
export const isValidUsPostalCode = (v: string) => [5, 9].includes(v.replace(/\D/g, "").trim().length);

const COUNTRIES_WITH_DIFFERENT_REGION_NAMES = Object.keys(info.region).filter((k) => CountryCode[k]) as CountryCode[];

export function getRegionLocaleId(country: CountryCode | undefined, label: "title" | "required"): IntlMessageKeys {
  return (country && COUNTRIES_WITH_DIFFERENT_REGION_NAMES.includes(country)
    ? `containers.info.region.${country}.${label}`
    : `containers.info.region.${label}`) as IntlMessageKeys;
}

export const COUNTRIES_WITH_DIFFERENT_POSTAL_NAMES = Object.keys(info.postalCode).filter(
  (k) => CountryCode[k],
) as CountryCode[];

export function getPostalLocaleId(
  country: CountryCode | undefined,
  label: "title" | "required" | "valid",
): IntlMessageKeys {
  return (country && COUNTRIES_WITH_DIFFERENT_POSTAL_NAMES.includes(country)
    ? `containers.info.postalCode.${country}.${label}`
    : `containers.info.postalCode.${label}`) as IntlMessageKeys;
}

function isEnabledCountry(c: string | null | undefined, config: Partial<IFrameConfig>): CountryCode | undefined {
  return isCountryCode(c) && (!config.enabledCountries?.length || config.enabledCountries.includes(c)) ? c : undefined;
}

function getInitialValues(
  recipient: Recipient,
  addr: Record<string, string | undefined>,
  config: Partial<IFrameConfig>,
): FormFields {
  return {
    type: recipient.type || addr.type || "individual",
    email: recipient.email || addr.email || "",
    name: recipient.name || addr.name,
    firstName: recipient.firstName || addr.firstName,
    lastName: recipient.lastName || addr.lastName,
    dob: recipient.dob || addr.dob,
    governmentIds: recipient.governmentIds,
    brn: recipient.brn,
    address: {
      phone: recipient.address.phone || addr.phone,
      street1: recipient.address.street1 || addr.street1,
      street2: recipient.address.street2 || addr.street2,
      city: recipient.address.city || addr.city,
      postalCode: recipient.address.postalCode || addr.postalCode,
      region: recipient.address.region || addr.region,
      country: isEnabledCountry(recipient.address.country, config) || isEnabledCountry(addr.country, config),
    },
  };
}

export default function Info() {
  useStepperEffect(ActionType.PAYOUT, 1 / 2);
  const [form] = Form.useForm<FormFields>();
  const config = useIframeConfig();
  const { isMobile } = useWindowSize();
  const { addr = {}, roEmail, hideEmail } = useUrlParams();
  const recipient = useRecipient();
  const merchant = useMerchant();
  const { queryProducts, entitledQueriedProducts } = useProducts();
  const { data: tickets } = useTickets();
  const [loading, setLoading] = useState(false);
  const primaryBankAccount = recipient?.accounts?.find((a) => a.primary && a.type === PayoutMethodType.BANKTRANSFER);
  const history = useHistory<{
    saveAndReturn?: boolean;
    modularWidgetPath?: string;
    profileVisited?: boolean;
    from?: string;
  } | null>();
  const saveAndReturn = !!history.location.state?.saveAndReturn;
  const modularWidgetPath = history.location.state?.modularWidgetPath;
  const from = history.location.state?.from;
  const intl = useIntl();
  const { formatMessage } = intl;
  const theme = useTheme();

  const type = (Form.useWatch("type", form) as "business" | "individual") as RecipientType;
  const country = Form.useWatch(["address", "country"], form);
  const firstNameRequired = type === RecipientType.INDIVIDUAL || !!recipient?.inactiveReasons?.firstName;
  const lastNameRequired = type === RecipientType.INDIVIDUAL || !!recipient?.inactiveReasons?.lastName;
  const requiresDOB =
    !!recipient?.inactiveReasons?.dob ||
    isRequiredToActivate("dob", country, type) ||
    tickets.records.some((t) => t.reasons[TicketReasonList.MISSING_DOB]);
  const requirePhoneVerification =
    !!merchant?.features?.phoneVerification &&
    !!merchant?.trust?.requirePhoneVerification &&
    showProduct({ product: PRODUCT_MODULES.TRUST, queryProducts, entitledQueriedProducts });
  const showKyb = !!merchant?.features?.dsa && merchant?.trust?.requireDSA;
  const isPhoneVerified = !!recipient?.isPhoneVerified;
  const [showPhoneVerification, setShowPhoneVerification] = useState(false);

  useEffect(() => {
    emitEvent({
      event: WidgetEvent.MODULE_LOADED,
      module: ["profile"],
    });
  }, []);

  if (!recipient) {
    return null;
  }

  function getFieldErrorStyle(field: keyof IncompleteReason) {
    const reason = recipient?.inactiveReasons?.[field];
    if (
      reason &&
      [IncompleteReasonType.INVALID, IncompleteReasonType.MISSING].includes(reason as IncompleteReasonType)
    ) {
      return {
        backgroundColor: theme.colorErrorBg,
        borderRadius: `${theme.borderRadius}px`,
        padding: `${theme.paddingXS}px`,
        marginBottom: `${theme.marginXS}px`,
      };
    }

    return undefined;
  }

  function handleHistory() {
    if (modularWidgetPath) {
      history.push({ pathname: modularWidgetPath, state: { profileVisited: true } });

      return;
    }
    if (saveAndReturn) {
      history.goBack();

      return;
    }

    history.push(PATHS.PAYOUT);
  }

  async function onSubmit(values: FormFields) {
    if (recipient) {
      try {
        setLoading(true);
        const newData: RecipientUpdate = JSON.parse(JSON.stringify(values)); // easy clone

        if (roEmail || hideEmail) {
          /**
           * query params the merchant passed when generating the widget.
           * should not be allowed to change the email
           * */

          delete newData.email;
        }

        const updatedRecipient = await updateRecipient(recipient.id, omitMaskedValues(newData));

        const INFO_PAGE_REASON: (keyof IncompleteReason)[] = [
          "firstName",
          "lastName",
          "dob",
          "governmentId",
          "countryCode",
          "phone",
          "city",
          "street1",
          "regionCode",
          "postalCode",
        ];

        // All fields on this page should not appear in "inactiveReasons"
        if (
          !updatedRecipient.inactiveReasons ||
          Object.keys(updatedRecipient.inactiveReasons).every(
            (reason: keyof IncompleteReason) =>
              !INFO_PAGE_REASON.includes(reason) ||
              ![IncompleteReasonType.MISSING, IncompleteReasonType.INVALID].includes(
                // other reasons like COUNTRY_MISMATCH are related to the payout method
                updatedRecipient.inactiveReasons?.[reason] as IncompleteReasonType,
              ),
          )
        ) {
          if (requirePhoneVerification && !updatedRecipient.isPhoneVerified) {
            setShowPhoneVerification(true);
          } else {
            handleHistory();
          }
        }
        loadRecipientConfig(recipient?.id, true);
      } catch (errors) {
        handleFormErrors(errors, form);
      }
      setLoading(false);
    }
  }

  return (
    <Form<FormFields>
      form={form}
      onFinish={onSubmit}
      initialValues={getInitialValues(recipient, addr, config)}
      requiredMark="optional"
    >
      <InactiveReasons recipient={recipient} />
      <Loader spinning={loading}>
        <Helmet>
          <title>
            {`${formatMessage({
              id: "containers.info.pageName",
              defaultMessage: info.pageName,
            })} - ${formatMessage({
              id: "containers.info.title",
              defaultMessage: info.title,
            })}`}
          </title>
        </Helmet>
        <Container>
          <TitleBar>
            {formatMessage({
              id: "containers.info.title",
              defaultMessage: info.title,
            })}
          </TitleBar>

          <Grid>
            {/* RECIPIENT TYPE */}
            <Grid.Item xs={24} sm={{ flex: "1 1 50%" }}>
              <Form.Item
                label={formatMessage({
                  id: "containers.info.type.title",
                  defaultMessage: info.type.title,
                })}
                name="type"
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: "containers.info.type.required",
                      defaultMessage: info.type.required,
                    }),
                  },
                ]}
              >
                <Form.Radio.Group
                  direction={isMobile ? "vertical" : "inline"}
                  name="type"
                  optionType="card"
                  disabled={!!from && from === PATHS.KYB_UPLOAD}
                  aria-label={formatMessage({
                    id: "containers.info.type.switchType",
                    defaultMessage: info.type.title,
                  })}
                  options={[
                    {
                      label: formatMessage({
                        id: "containers.info.type.individual",
                        defaultMessage: info.type.individual,
                      }),
                      value: "individual",
                      "aria-label": formatMessage({
                        id: "containers.info.type.switchToIndividual",
                        defaultMessage: info.type.individual,
                      }),
                    },
                    {
                      label: formatMessage({
                        id: "containers.info.type.business",
                        defaultMessage: info.type.business,
                      }),
                      value: "business",
                      "aria-label": formatMessage({
                        id: "containers.info.type.switchToBusiness",
                        defaultMessage: info.type.business,
                      }),
                    },
                  ]}
                />
              </Form.Item>
            </Grid.Item>
          </Grid>

          <Grid>
            {/* BUSINESS NAME */}
            {type === RecipientType.BUSINESS && (
              <Grid.Item xs={24} sm={12}>
                <Form.Item
                  label={formatMessage({
                    id: "containers.info.name.title",
                    defaultMessage: info.name.title,
                  })}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "containers.info.name.required",
                        defaultMessage: info.name.required,
                      }),
                    },
                  ]}
                >
                  <Form.Input type="text" name="name" />
                </Form.Item>
              </Grid.Item>
            )}

            {/* BRN(Business registration number) */}
            {showBrn({ merchant, queryProducts, entitledQueriedProducts, type }) && (
              <Grid.Item xs={24} sm={12}>
                <Form.Item
                  label={formatMessage({
                    id: "containers.info.brn.title",
                    defaultMessage: info.brn.title,
                  })}
                  name="brn"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "containers.info.brn.required",
                        defaultMessage: info.brn.required,
                      }),
                    },
                    ({ getFieldValue }) => ({
                      async validator(_: any, brn: string) {
                        const recipientType = getFieldValue("type");
                        const recipientBRN = getFieldValue("brn");
                        const recipientName = getFieldValue("name");
                        const recipientRegion = getFieldValue(["address", "region"]);
                        const recipientStreet1 = getFieldValue(["address", "street1"]);
                        const recipientCountry = getFieldValue(["address", "country"]);
                        const correspondsBusinessVerification =
                          showKyb &&
                          recipientType &&
                          recipientBRN &&
                          recipientName &&
                          recipientRegion &&
                          recipientStreet1 &&
                          recipientCountry;
                        if (correspondsBusinessVerification && recipientName === brn)
                          throw new Error(formatMessage({ id: "containers.info.brn.notIndentical" }));
                      },
                    }),
                  ]}
                >
                  <Form.Input type="text" name="brn" />
                </Form.Item>
              </Grid.Item>
            )}

            {/* FIRST NAME */}
            <Grid.Item xs={24} sm={12} style={getFieldErrorStyle("firstName")}>
              <Form.Item
                label={formatMessage({
                  id: "containers.info.firstName.title",
                  defaultMessage: info.firstName.title,
                })}
                name="firstName"
                dependencies={["type"]}
                rules={[
                  {
                    required: firstNameRequired,
                    message: formatMessage({
                      id: "containers.info.firstName.required",
                      defaultMessage: info.firstName.required,
                    }),
                  },
                ]}
              >
                <Form.Input type="text" name="firstName" />
              </Form.Item>
            </Grid.Item>

            {/* LAST NAME */}
            <Grid.Item xs={24} sm={12} style={getFieldErrorStyle("lastName")}>
              <Form.Item
                label={formatMessage({
                  id: "containers.info.lastName.title",
                  defaultMessage: info.lastName.title,
                })}
                dependencies={["type"]}
                name="lastName"
                rules={[
                  {
                    required: lastNameRequired,
                    message: formatMessage({
                      id: "containers.info.lastName.required",
                      defaultMessage: info.lastName.required,
                    }),
                  },
                ]}
              >
                <Form.Input type="text" name="lastName" />
              </Form.Item>
            </Grid.Item>

            {/* EMAIL */}
            {!hideEmail && (
              <Grid.Item xs={24} sm={12}>
                <Form.Item
                  name="email"
                  label={formatMessage({
                    id: "containers.info.email.title",
                    defaultMessage: info.email.title,
                  })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "containers.info.email.required",
                        defaultMessage: info.email.required,
                      }),
                    },
                    { type: "email", message: formatMessage({ id: "common.invalidValue" }) },
                  ]}
                >
                  <Form.Input type="email" name="email" readOnly={roEmail} />
                </Form.Item>
              </Grid.Item>
            )}

            {/* DATE OF BIRTH */}
            {type === RecipientType.INDIVIDUAL &&
              (requiresDOB || ["optional", "required"].includes(String(config.dobRequirement))) && (
                <Grid.Item xs={24} sm={12} style={getFieldErrorStyle("dob")}>
                  <Form.Item
                    name="dob"
                    label={formatMessage({
                      id: "containers.info.dob.title",
                      defaultMessage: info.dob.title,
                    })}
                    rules={[
                      {
                        required: requiresDOB || config.dobRequirement === "required",
                        message: formatMessage({
                          id: "containers.info.dob.required",
                          defaultMessage: info.dob.required,
                        }),
                      },
                      isLegalAgeDateValidator(intl.formatMessage),
                    ]}
                  >
                    <Form.DatePicker />
                  </Form.Item>
                </Grid.Item>
              )}

            {/* COUNTRY */}
            <Grid.Item xs={24} sm={12} style={getFieldErrorStyle("countryCode")}>
              <Form.Item
                name={["address", "country"]}
                label={formatMessage({
                  id: "containers.info.country.title",
                  defaultMessage: info.country.title,
                })}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: "containers.info.country.required",
                      defaultMessage: info.country.required,
                    }),
                  },
                ]}
              >
                <Form.SelectCountry
                  type="address"
                  includes={config.enabledCountries}
                  onChange={() => {
                    form.setFieldsValue({
                      address: {
                        region: undefined, // reset regions
                      },
                    });
                  }}
                />
              </Form.Item>
            </Grid.Item>

            {/* PHONE  */}
            {!showPhone({
              merchant,
              country,
              recipient,
              queryProducts,
              entitledQueriedProducts,
            }) && (
              <Grid.Item xs={24} sm={12} style={getFieldErrorStyle("phone")}>
                <Form.Item
                  name={["address", "phone"]}
                  label={formatMessage({
                    id: "containers.info.phone.title",
                    defaultMessage: info.phone.title,
                  })}
                  extraLabel={
                    requirePhoneVerification &&
                    (isPhoneVerified ? (
                      <Status type="success">
                        {formatMessage({ id: "containers.info.phoneVerification.verified" })}
                      </Status>
                    ) : (
                      <Status type="default">
                        {formatMessage({ id: "containers.info.phoneVerification.unverified" })}
                      </Status>
                    ))
                  }
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "containers.info.phone.required",
                        defaultMessage: info.phone.required,
                      }),
                    },
                    countryPhoneValidator(formatMessage),
                  ]}
                >
                  <Form.InputPhoneProfile name="phone" allowClear defaultCountry={country} />
                </Form.Item>
              </Grid.Item>
            )}

            {/* GOV IDS - NEW */}
            {showGovernmentId({
              country,
              type,
              recipient,
              tickets: tickets.records,
              queryProducts,
              entitledQueriedProducts,
            }) && (
              <Grid.Item xs={24} style={getFieldErrorStyle("governmentId")}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "containers.info.governmentIds.required",
                        defaultMessage: info.governmentIds.required,
                      }),
                    },
                    govermentIdsValidator(intl.formatMessage),
                  ]}
                  name="governmentIds"
                  label={formatMessage({
                    id: "containers.info.governmentIds.title",
                    defaultMessage: info.governmentIds.title,
                  })}
                  extra={formatMessage({
                    id: "containers.info.governmentIds.tooltip",
                    defaultMessage: info.governmentIds.tooltip,
                  })}
                >
                  <Form.GovernmentIds
                    legend={formatMessage({
                      id: "containers.info.governmentIds.title",
                      defaultMessage: info.governmentIds.title,
                    })}
                    recipient={recipient}
                    defaultCountry={
                      (recipient?.inactiveReasons?.governmentId && primaryBankAccount?.country) || country
                    } // when inactive reason govermentId is present, it's usually tied to the bank account country}
                  />
                </Form.Item>
              </Grid.Item>
            )}

            {/* STREET 1 */}
            <Grid.Item xs={24} sm={12} style={getFieldErrorStyle("street1")}>
              <Form.Item
                name={["address", "street1"]}
                label={formatMessage({
                  id: "containers.info.street1.title",
                  defaultMessage: info.street1.title,
                })}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: "containers.info.street1.required",
                      defaultMessage: info.street1.required,
                    }),
                  },
                ]}
              >
                <Form.Input type="text" name="street1" />
              </Form.Item>
            </Grid.Item>
            {/* STREET 2 */}
            <Grid.Item xs={24} sm={12}>
              <Form.Item
                label={formatMessage({
                  id: "containers.info.street2.title",
                  defaultMessage: info.street2.title,
                })}
                name={["address", "street2"]}
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(_: any, street2: string) {
                      const recipientStreet1 = getFieldValue(["address", "street1"]);
                      if (recipientStreet1 === street2)
                        throw new Error(formatMessage({ id: "containers.info.street2.notIndentical" }));
                    },
                  }),
                ]}
              >
                <Form.Input type="text" name="street2" />
              </Form.Item>
            </Grid.Item>
            {/* CITY */}
            <Grid.Item xs={24} sm={12} style={getFieldErrorStyle("city")}>
              <Form.Item
                name={["address", "city"]}
                label={formatMessage({
                  id: "containers.info.city.title",
                  defaultMessage: info.city.title,
                })}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: "containers.info.city.required",
                      defaultMessage: info.city.required,
                    }),
                  },
                ]}
              >
                <Form.Input type="text" name="city" />
              </Form.Item>
            </Grid.Item>
            {/* REGION */}
            {country && !!allMappedCountries[country]?.regions.length && (
              <Grid.Item xs={24} sm={12} style={getFieldErrorStyle("regionCode")}>
                <Form.Item
                  key={country} // rerender component when country changes
                  name={["address", "region"]}
                  label={formatMessage({
                    id: COUNTRIES_WITH_DIFFERENT_REGION_NAMES.includes(country)
                      ? (`containers.info.region.${country}.title` as IntlMessageKeys)
                      : "containers.info.region.title",
                    defaultMessage: info.region.title,
                  })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "containers.info.region.required",
                        defaultMessage: info.region.required,
                      }),
                    },
                    isRegionInCountryValidator(["address", "country"], formatMessage),
                  ]}
                >
                  <Form.SelectRegion country={country} />
                </Form.Item>
              </Grid.Item>
            )}
            {/* POSTAL CODE */}
            <Grid.Item xs={24} sm={12} style={getFieldErrorStyle("postalCode")}>
              <Form.Item
                name={["address", "postalCode"]}
                label={formatMessage({
                  id: getPostalLocaleId(country, "title"),
                })}
                rules={[
                  {
                    required: isRequiredToActivate("postalCode", country) || !!recipient.inactiveReasons?.postalCode,
                    message: formatMessage({
                      id: getPostalLocaleId(country, "required"),
                    }),
                  },
                ]}
                extra={getPostalCodeProps(country, formatMessage)?.hint}
              >
                <Form.Input type="text" name="postalCode" />
              </Form.Item>
            </Grid.Item>
          </Grid>
          <Footer
            main={
              <Button type="primary" size="large" loading={loading} htmlType="submit">
                {formatMessage({
                  id: saveAndReturn ? "common.save" : "common.next",
                  defaultMessage: saveAndReturn ? common.save : common.next,
                })}
              </Button>
            }
            extra={
              <BackButton
                disabled={!!modularWidgetPath}
                title={formatMessage({
                  id: saveAndReturn ? "common.cancel" : "common.back",
                })}
              />
            }
          />

          <Form.Control dependencies={[["address", "phone"]]}>
            {({ getFieldValue }) => {
              const phone = getFieldValue(["address", "phone"]);

              return (
                requirePhoneVerification &&
                !isPhoneVerified && (
                  <PhoneVerificationPopup
                    visible={showPhoneVerification}
                    onClose={() => setShowPhoneVerification(false)}
                    onOk={() => {
                      setShowPhoneVerification(false);
                      handleHistory();
                    }}
                    submitting={loading}
                    phone={phone}
                  />
                )
              );
            }}
          </Form.Control>
        </Container>
      </Loader>
    </Form>
  );
}
