import React from "react";
import { useIframeConfig } from "store/hooks/config";
import { useRecipient } from "store/hooks/recipient";
import FirstVerification from "./FirstVerification";
import OnGoingVerification from "./OnGoingVerification";
import VerificationHistory from "./VerificationHistory";

export default function Trust() {
  const recipient = useRecipient();
  const config = useIframeConfig();

  if (!config.showVerificationHistory && (!!recipient?.idvStatus || !!recipient?.kybStatus)) {
    return <OnGoingVerification />;
  }

  if (config.showVerificationHistory) {
    return <VerificationHistory />;
  }

  return <FirstVerification />;
}

export { default as KybUpload } from "./KybUpload";
