import { RecipientType, VerificationStatus } from "@trolley/common-frontend";
import { Container, Divider, List, Notification, TitleBar } from "components";
import React, { useMemo } from "react";
import { Recipient } from "store/actions/recipient";
import { useRecipient } from "store/hooks/recipient";
import css, { createUseStyle } from "style/classname";
import { useIntl } from "utils/context";
import IdVerificationButton, { isVerificationInProgress } from "./IdVerificationButton";
import BasicCardLayout from "./BasicCardLayout";
import { useMerchant } from "store/hooks/merchant";
import IdvStatusDisplay from "./IdvStatusDisplay";

export default function OnGoingVerification() {
  const { formatMessage } = useIntl();
  const recipient = useRecipient();
  const styledContainer = useStyledContainer();
  const merchant = useMerchant();

  const verificationInProgress = useMemo(() => isVerificationInProgress(merchant, recipient), [merchant, recipient]);

  function renderIndividualorBusinessHistory(key: "kybStatus" | "idvStatus") {
    return function renderHistory(item: Recipient) {
      return (
        <Container padding="medium" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <BasicCardLayout
            recipientType={key === "kybStatus" ? RecipientType.BUSINESS : RecipientType.INDIVIDUAL}
            statusElement={
              recipient?.[key] === "submitted" ? (
                <IdvStatusDisplay
                  status={VerificationStatus.SUBMITTED}
                  checkSubmitted={!merchant?.trust?.requireVerificationToBeActive}
                />
              ) : undefined
            }
          />
          {recipient?.[key] === "submitted" && !!merchant?.trust?.requireVerificationToBeActive && (
            <Container padding="small" style={{ marginTop: 10 }}>
              <Notification
                type="info"
                // forcing the color because info notification has a different color in the old design's theme
                style={{ backgroundColor: "#E6F2FF" }}
                title={formatMessage({ id: "containers.trust.verificationAlert.submittedNoHistory" })}
              />
            </Container>
          )}
        </Container>
      );
    };
  }

  return (
    <Container className={styledContainer}>
      <TitleBar level={2}>{formatMessage({ id: "containers.trust.verifications" })}</TitleBar>
      {recipient?.idvStatus && (
        <List<Recipient>
          items={recipient ? [recipient] : []}
          renderItem={renderIndividualorBusinessHistory("idvStatus")}
          defaultRecordCount={1}
        />
      )}
      {recipient?.idvStatus && recipient?.kybStatus && <Divider transparent />}
      {recipient?.kybStatus && (
        <List<Recipient>
          items={recipient ? [recipient] : []}
          renderItem={renderIndividualorBusinessHistory("kybStatus")}
          defaultRecordCount={1}
        />
      )}
      <Divider transparent size="small" />
      {(verificationInProgress || recipient?.verificationAttemptAvailable) && (
        <IdVerificationButton
          reVerification={
            (recipient?.idvStatus && recipient.type === RecipientType.INDIVIDUAL) ||
            (recipient?.kybStatus && recipient.type === RecipientType.BUSINESS)
          }
          placement="right"
        />
      )}
    </Container>
  );
}

const useStyledContainer = createUseStyle(({ theme }) =>
  css`
    border: 1px solid ${theme.colorBorder};
    border-radius: ${theme.borderRadius}px;
    border: none;
    min-height: auto;
  `(),
);

export { default as KybUpload } from "./KybUpload";
