import { BaseError } from "@trolley/common-frontend";
import { Layout, Switch } from "antd";
import { Tooltip } from "components";
import config from "config";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import Favicon from "react-favicon";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { loadAuth, loadAuthToken } from "store/actions/auth";
import { authPortal, getUrlPortalDomain } from "store/actions/authPortal";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useUrlParams } from "store/hooks/params";
import colors from "style/colors";
import { ClientProvider, ConnectedIntlProvider, PRODUCT_MODULES, ProductsProvider, ThemeProvider } from "utils/context";
import { defaultConfig } from "utils/context/ThemeProvider";
import { WidgetEvent, capitalize, emitEvent, omitEmpty } from "utils/helpers";

let timer: number | undefined;

type Props = {
  children?: React.ReactNode;
};

export const NewLayoutContext = createContext(config.ENV !== "production");

export function useNewStyle() {
  return useContext(NewLayoutContext);
}

export default function App(props: Props) {
  const [client, setClient] = useState(config.CLIENT);
  const app = useRef<null | HTMLDivElement>(null);
  const params = useUrlParams();
  const [appHeight, setAppHeight] = useState(app.current?.offsetHeight || 0);
  const [newStyle, setNewStyle] = useState(config.ENV !== "production");
  const [queryProducts, setQueryProducts] = useState<PRODUCT_MODULES[]>([]);
  const [entitledQueriedProducts, setEntitledQueriedProducts] = useState<PRODUCT_MODULES[]>([]);
  const history = useHistory();
  const iframeConfig = useIframeConfig();
  const merchant = useMerchant();

  const clientConfig = useMemo(() => {
    return client === "widget"
      ? {
          colors: {
            ...defaultConfig.colors,
            ...omitEmpty(iframeConfig.colors || {}), // only widge takes config colors. portal takes default colors
            ...omitEmpty(params.colors || {}), // widget must accept params colors
          },
          style: {
            ...defaultConfig.style,
            ...omitEmpty(iframeConfig.style || {}), // only widge takes config colors. portal takes default colors
            ...omitEmpty(params.style || {}), // widget must accept params colors
          },
        }
      : {
          colors: {
            ...defaultConfig.colors,
            brandColor: iframeConfig.colors?.brandColor || colors.blue, // portal only needs brand color;
          },
          style: defaultConfig.style,
        };
  }, [iframeConfig, params, client, newStyle]);

  useEffect(() => {
    if (merchant) {
      const { entitledProducts } = merchant;
      const { products } = params;
      // filter valid Product Modules: tax, pay, trust
      const queryProducts = (products?.filter((p) => Object.values<string>(PRODUCT_MODULES).includes(p ?? "")) ??
        []) as PRODUCT_MODULES[];
      if (entitledProducts && queryProducts.length > 0) {
        setQueryProducts(queryProducts);
        setEntitledQueriedProducts(queryProducts.filter((qp) => entitledProducts[qp]));
      }
    }
  }, [merchant, params]);

  useEffect(() => {
    const raw = window.location.search.substring(1);

    async function loginWithRaw() {
      await loadAuth(raw);
    }

    async function loginPortal(previewErrors?: BaseError[]) {
      const portalDomain = getUrlPortalDomain();
      if (client === "portal" && portalDomain) {
        try {
          await authPortal(portalDomain);
        } catch (errors) {
          const invalidFieldError: BaseError = errors?.find?.(
            (e: BaseError) => ["portalDomain", "merchantId"].includes(e.field || "") && e.code === "invalid_field",
          );
          if (invalidFieldError) {
            throw [
              {
                ...invalidFieldError,
                message:
                  invalidFieldError.field === "portalDomain" ? "Invalid Portal Domain" : "Invalid Merchant Account", // to display on the 404 page
              },
            ];
          }

          throw errors;
        }
      } else {
        throw previewErrors;
      }
    }

    loadAuthToken(raw) // attempt to login with token in sessionStorage
      .catch(loginWithRaw) // attempt to login using the raw signed url if any
      .catch(loginPortal) // attempt to use portal auth (to get the merchatn info)
      .catch((errors) => {
        history.replace("/404", { errors });
      });
  }, []);

  useEffect(() => {
    // Event Emitter to show app height
    if (client === "widget") {
      timer = window.setInterval(() => {
        if (app.current) {
          const currentHeight = app.current.offsetHeight;
          if (currentHeight !== appHeight) {
            setAppHeight(currentHeight);
            emitEvent({
              event: WidgetEvent.DOCUMENT_HEIGHT,
              document: {
                height: currentHeight + 40,
              },
              data: currentHeight + 40,
            });
          }
        }
      }, 250);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
        timer = undefined;
      }
    };
  }, [appHeight, app.current]);

  return (
    <NewLayoutContext.Provider value={newStyle}>
      <ThemeProvider iframeConfig={clientConfig}>
        <ConnectedIntlProvider>
          <ClientProvider value={client}>
            <ProductsProvider
              value={{
                queryProducts,
                entitledQueriedProducts,
              }}
            >
              <Layout ref={app}>
                {config.ENV !== "production" && (
                  <>
                    <Switch
                      checked={client === "portal"}
                      onChange={(value) => {
                        setClient((value ? "portal" : "widget") as "widget" | "portal");
                      }}
                      checkedChildren="Portal"
                      unCheckedChildren="Widget"
                      style={{
                        position: "absolute",
                        top: "8px",
                        left: "8px",
                        width: "80px",
                        zIndex: 100,
                      }}
                    />
                    <Tooltip title="New Layout will only appear for non-production env. We need to communicate to customers about the changes first.">
                      <Switch
                        checked={newStyle}
                        onChange={(value) => {
                          setNewStyle(value);
                        }}
                        checkedChildren="New Layout"
                        unCheckedChildren="Old Layout"
                        style={{
                          position: "absolute",
                          top: "8px",
                          left: "96px",
                          width: "120px",
                          zIndex: 100,
                        }}
                      />
                    </Tooltip>
                  </>
                )}
                <Helmet
                  titleTemplate={["%s", `${iframeConfig.businessName || "Trolley"} ${capitalize(client)}`].join(" - ")}
                  defaultTitle={[iframeConfig.businessName || "Trolley", capitalize(client)].join(" ")}
                />
                {client === "portal" && iframeConfig.icon && <Favicon url={iframeConfig.icon} />}

                {props.children}
              </Layout>
            </ProductsProvider>
          </ClientProvider>
        </ConnectedIntlProvider>
      </ThemeProvider>
    </NewLayoutContext.Provider>
  );
}
