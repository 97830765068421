import {
  CountryCode,
  CurrencyCode,
  getRegionLabel,
  NoBankingCountries,
  PayoutMethodType,
  WidgetMerchant,
} from "@trolley/common-frontend";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import { Container, Divider, GreyBox, LinkButton, Loader, Text, TitleBar } from "components";
import { CurrenciesLoader, DisabledNotice } from "features";
import { PATHS } from "pages/App/routes";
import { isCountryEnabledForPaypal } from "pages/PayoutMethod2/NotificationDisabledCountry";
import { isVenmoSettings } from "store/actions/recipient";
import { useMerchant } from "store/hooks/merchant";
import { useUrlParams } from "store/hooks/params";
import { useRecipient } from "store/hooks/recipient";
import { ActionType, PRODUCT_MODULES, useIntl, useStepperEffect } from "utils/context";
import { emitEvent, stringifyAddress, WidgetEvent } from "utils/helpers";
import { integrationToFeature } from "../AccountSummary/RecipientAccounts";
import BankTransfer from "./BankTransfer";
import Bitcoin from "./Bitcoin";
import Check, { checkCountries } from "./Check";
import Interac from "./Interac";
import PayoutMethodPicker from "./PayoutMethodPicker";
import PayPal from "./PayPal";
import Venmo from "./Venmo";

export default function PayoutMethod() {
  useStepperEffect(ActionType.PAYOUT, 2 / 2);
  const recipient = useRecipient();
  const { hideEmail, payoutMethods: paramsEnabledPayoutMethods = [] } = useUrlParams();
  const merchant = useMerchant();
  const { formatMessage } = useIntl();
  const { accountId } = useParams<{ accountId?: string }>();
  const account = accountId ? recipient?.accounts?.find((a) => a.id === accountId) : undefined;
  const recipientCountry = (recipient?.address.country as CountryCode) || "";
  const merchantPayPalSettings = merchant?.enabledPayoutMethods.find(
    (pm) => pm.integration === PayoutMethodType.PAYPAL,
  );
  const [currency, setCurrency] = useState<CurrencyCode | null>(null);
  const [country, setCountry] = useState<CountryCode | null>(null);

  useEffect(() => {
    emitEvent({
      event: WidgetEvent.MODULE_LOADED,
      module: [PRODUCT_MODULES.PAY],
    });
  }, []);

  // default should be based on whats enabled
  const enabledPayoutMethods = merchant?.enabledPayoutMethods
    .filter((pm) => {
      const integration = pm.integration || "";
      if (integrationToFeature[integration] && !merchant.features?.[integrationToFeature[integration]]) {
        return false;
      }

      return (
        account?.type === pm.integration ||
        !pm.enabledCountries?.length ||
        pm.enabledCountries.includes(recipientCountry)
      );
    })
    .filter((pm) => {
      const integration = pm.integration || "";

      if (integration === PayoutMethodType.INTERAC) {
        return account?.type === pm.integration || [CountryCode.CA].includes(recipientCountry);
      } else if (integration === PayoutMethodType.CHECK) {
        return account?.type === pm.integration || checkCountries.includes(recipientCountry);
      } else if (integration === PayoutMethodType.BANKTRANSFER) {
        return account?.type === pm.integration || !NoBankingCountries.includes(recipientCountry);
      } else if (integration === PayoutMethodType.PAYPAL) {
        return account?.type === pm.integration || isCountryEnabledForPaypal(merchantPayPalSettings, recipientCountry);
      } else if (integration === PayoutMethodType.VENMO) {
        return (
          account?.type === pm.integration ||
          (isVenmoSettings(pm.integration, pm.settings) && pm.settings?.allowedCountries.includes(recipientCountry))
        );
      }

      return true;
    });

  const selectablePayoutMethods = getSelectablePayoutMethods(enabledPayoutMethods, paramsEnabledPayoutMethods);

  const [selectedMethod, setSelectedMethod] = useState<PayoutMethodType | undefined>(
    account ? (account.type as PayoutMethodType) : selectablePayoutMethods?.[0]?.integration,
  );

  if (!recipient || !merchant) {
    // not possible. this is to fix type casting
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: "containers.payoutMethod.headers.payoutMethod" })}</title>
      </Helmet>

      <Container padding="large">
        <TitleBar>{formatMessage({ id: "containers.payoutMethod.title" })}</TitleBar>
        <Divider transparent />

        <GreyBox type="aside">
          <TitleBar
            level={2}
            align="start"
            extraActions={
              <LinkButton to={{ pathname: PATHS.INFO, state: { saveAndReturn: true } }} icon="pencil" size="middle">
                {formatMessage({ id: "common.edit" })}
              </LinkButton>
            }
          >
            {formatMessage({ id: "containers.info.title" })}
          </TitleBar>
          <Text type="secondary">
            {recipient.type === "business" && `${recipient.name}, `}
            {!!(recipient.firstName && recipient.lastName) && `${recipient.firstName} ${recipient.lastName}`}
            {!hideEmail && ` (${recipient.email})`}
          </Text>
          <Text type="secondary">
            <strong>
              {formatMessage({ id: "containers.bankPayoutMethod.address" })}
              :&nbsp;
            </strong>
            {stringifyAddress({
              ...recipient.address,
              region: getRegionLabel(recipient.address.region, recipientCountry),
              country: formatMessage({ id: `countries.${recipientCountry}` }), // since we have common-frontend keys locally... seems simpler
            })}
          </Text>
        </GreyBox>

        <PayoutMethodPicker
          account={account}
          enabledPayoutMethods={selectablePayoutMethods}
          selectedMethod={selectedMethod}
          onSelect={setSelectedMethod}
          currency={currency ?? undefined}
          country={country ?? undefined}
        />

        {account?.status === "disabled" && <DisabledNotice account={account} />}

        {selectedMethod === PayoutMethodType.BANKTRANSFER && (
          <CurrenciesLoader>
            {(currencies) => (
              <BankTransfer
                account={account}
                currencies={currencies}
                onCurrencyChange={(c: CurrencyCode) => {
                  setCurrency(c);
                }}
                onCountryChange={(c: CountryCode) => {
                  setCountry(c);
                }}
              />
            )}
          </CurrenciesLoader>
        )}
        {selectedMethod === PayoutMethodType.PAYPAL && <PayPal account={account} />}
        {selectedMethod === PayoutMethodType.BITCOIN && <Bitcoin account={account} />}
        {selectedMethod === PayoutMethodType.CHECK && <Check account={account} />}
        {selectedMethod === PayoutMethodType.INTERAC && <Interac account={account} />}
        {selectedMethod === PayoutMethodType.VENMO && <Venmo account={account} />}
      </Container>
    </>
  );
}

function getSelectablePayoutMethods(
  enabledPayoutMethods: WidgetMerchant["enabledPayoutMethods"] | undefined,
  paramsEnabledPayoutMethods: string[],
) {
  let filteredMethods = enabledPayoutMethods?.filter((pm) => paramsEnabledPayoutMethods.includes(pm.integration || ""));

  if (!filteredMethods?.length) {
    filteredMethods = enabledPayoutMethods;
  }

  // sort bank transfer first
  filteredMethods?.sort((a, b) =>
    a.integration === PayoutMethodType.BANKTRANSFER ? -1 : b.integration === PayoutMethodType.BANKTRANSFER ? 1 : 0,
  );

  return filteredMethods;
}
