import { Container, Divider, LinkButton, Paragraph, Text, TitleBar } from "components";
import { PATHS } from "pages/App/routes";
import React from "react";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { useIntl, PRODUCT_MODULES, useProducts } from "utils/context";
import { showProduct } from "utils/helpers";
import { BigCompleteIcon, CompletedIcon, IncompleteIcon } from "./PayoutComplete";
import Helmet from "react-helmet";

export default function TaxComplete() {
  const recipient = useRecipient();
  const merchant = useMerchant();
  const config = useIframeConfig();
  const { formatMessage } = useIntl();
  const { queryProducts, entitledQueriedProducts } = useProducts();
  const showPayProduct = showProduct({ product: PRODUCT_MODULES.PAY, queryProducts, entitledQueriedProducts });
  if (!merchant || !recipient) {
    return null;
  }
  const showPayoutMethods = config.showPayoutMethods;
  const hasEnabledPayoutSetup = recipient.accounts.some((a) =>
    merchant?.enabledPayoutMethods.some((p) => p.integration === a.type),
  );
  const addPayoutMethod = showPayProduct && showPayoutMethods && !hasEnabledPayoutSetup;

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: "containers.taxComplete.title" })}</title>
      </Helmet>
      <Container style={{ textAlign: "center" }}>
        <Divider transparent size="xlarge" />
        <BigCompleteIcon />
        <Divider transparent />
        <TitleBar>{formatMessage({ id: "containers.taxComplete.title" })}</TitleBar>
        <Divider transparent size="large" />
        {showPayProduct && showPayoutMethods && (
          <ul style={{ listStyleType: "none" }}>
            {hasEnabledPayoutSetup ? (
              <li>
                <Text type="success" padded data-test="preferencesSetup">
                  <CompletedIcon />
                  {formatMessage({ id: "containers.taxComplete.preferencesSetup" })}
                </Text>
              </li>
            ) : (
              <li>
                <Text padded type="secondary" size="small" data-test="preferencesNotSetup">
                  <IncompleteIcon />
                  {formatMessage({
                    id: "containers.taxComplete.preferencesNotSetup",
                  })}
                </Text>
              </li>
            )}
            <li>
              <Text type="success" padded data-test="taxFormCompleted">
                <CompletedIcon />
                {formatMessage({ id: "containers.taxComplete.taxFormCompleted" })}
              </Text>
            </li>
          </ul>
        )}
        <Paragraph limitWordPerLine style={{ margin: "0 auto" }} data-test="taxReceived">
          {formatMessage(
            {
              id: "containers.taxComplete.taxReceived",
            },
            {
              requiresReview: merchant.tax?.requiresReview
                ? formatMessage({ id: "containers.taxComplete.requiresReview" })
                : "",
            },
          )}
        </Paragraph>
        {addPayoutMethod && (
          <>
            <Divider transparent />
            <Paragraph limitWordPerLine style={{ margin: "0 auto" }} data-test="needPayoutMethod">
              {formatMessage({ id: "containers.taxComplete.needPayoutMethod" })}
            </Paragraph>
            <Divider transparent />
            <LinkButton size="large" type="primary" to={PATHS.PAYOUT} data-test="addPayoutMethod">
              {formatMessage({ id: "containers.taxComplete.addPayoutMethod" })}
            </LinkButton>
          </>
        )}
        <Divider transparent />
        <LinkButton type={addPayoutMethod ? "default" : "primary"} to={PATHS.HOME} size="large">
          {formatMessage({ id: "common.done" })}
        </LinkButton>
      </Container>
    </>
  );
}
